import React from 'react';

const IconLogo = () => (
	<svg width="43" height="43" viewBox="0 0 139 155" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="139" height="155" fill="#currentColor"/>
		<path d="M101.075 22.49L101.211 22.5746L101.352 22.6502L126.455 36.0898C131.148 38.6026 134.01 43.5598 133.84 48.8807L132.928 77.3398L132.922 77.5L132.928 77.6602L133.84 106.119C134.01 111.44 131.148 116.397 126.455 118.91L101.352 132.35L101.211 132.425L101.075 132.51L76.8848 147.529C72.362 150.338 66.638 150.338 62.1152 147.529L37.9249 132.51L37.7888 132.425L37.6475 132.35L12.5451 118.91C7.8518 116.397 4.98976 111.44 5.1603 106.119L6.07244 77.6602L6.07757 77.5L6.07244 77.3398L5.1603 48.8807C4.98976 43.5598 7.8518 38.6026 12.5451 36.0898L37.6475 22.6502L37.7888 22.5746L37.9249 22.49L62.1152 7.47053C66.638 4.66239 72.362 4.66239 76.8848 7.47053L101.075 22.49Z" stroke="#64FFDA" stroke-width="10"/>
		<path d="M60.2031 121V68.5938H44.8281V52.375H94.2812V68.5938H78.9062V121H60.2031Z" fill="#64FFDA"/>
	</svg>
	
);

export default IconLogo;
